import {fetchTimeout} from "../../common/fetch-timeout";
import {changeHTML} from "../../common/helper";

class HinzugefuegtOverlay extends HTMLElement {
  connectedCallback() {
    this.dialog = this.querySelector("dialog");
    this.registerListener();
  }

  registerListener() {
    this.dialog.addEventListener("click", (event) => this.handleClickEvent(event));
    document.addEventListener("open-wk-overlay", (event) => this.handleCustomEvent(event));
  }

  handleCustomEvent(event) {
    const origin = event.detail.origin;
    this.buttonCustomElement = this.getCustomElementButton(origin);
    const artikelnummer = event.detail.artikelnummer;
    
    this.setButtonState(this.buttonCustomElement, "loading");

    if (origin.closest("dialog.step0overlay")) {
      this.handleAddToCartWithinOverlay(artikelnummer);
    } else {
      this.loadAndOpenDialog(artikelnummer);
    }
  }

  async loadAndOpenDialog(artikelnummer) {
    const openOverlay = true;
    const headeronly = false;
    this.fetchContent(artikelnummer, this.dialog, openOverlay, headeronly)
  }

  async handleAddToCartWithinOverlay(artikelnummer) {
    const dialogheader = this.dialog.querySelector(".container-header");
    const openOverlay = false;
    const headeronly = true;
    this.fetchContent(artikelnummer, dialogheader, openOverlay, headeronly)
  }

  async fetchContent(artikelnummer, container, openOverlay, headeronly) {
    try {
      const config = {
        method: 'GET',
        headers: { 'Content-Type': 'text/html' }
      };
      const response = await fetchTimeout(`/warenkorb/overlay/hinzufuegen?headeronly=${headeronly}&artikelnummer=${artikelnummer}`, config, true);
      if (response.status === 200 || response.status === 400) {
        const fetchedHTML = await response.text();
        changeHTML(container, fetchedHTML);

        if (openOverlay) {
          this.showOverlay();
        }

        this.setButtonState(this.buttonCustomElement, "success");

        this.dispatchEvent(new CustomEvent("warenkorb.update", { bubbles: true }));
      }
    } catch (error) {
      console.log("FetchError!" + error)
    }
  }

  showOverlay() {
    this.dialog.showModal();
    // Öffnen vom Overlay in die Browser-history schreiben
    history.pushState(null, '', window.location.href);
    initBrowserBackListener();
  }

  handleClickEvent(event) {
    const target = event.target;
    if (target.closest(".close-overlay") || target.closest(".weiter-einkaufen")) {
      this.dialog.close();
      // Wenn Kunde den Dialog über die Schließen-Buttons schließt, muss die history bereinigt werden.
      history.back();
    }
  }

  getCustomElementButton(context) {
    return context.closest("warenkorb-button-vis") || context.closest("warenkorb-button-artikelfokus") || context.closest("warenkorb-button-listenkauf") || context.closest("addtocart-button");
  }

  setButtonState(wkButton, state) {
    if (wkButton !== null) {
      wkButton.setAttribute("data-state", state);
    }
  }

}

if (!customElements.get("hinzugefuegt-overlay")) {
  customElements.define("hinzugefuegt-overlay", HinzugefuegtOverlay);
}
document.querySelector('body').insertAdjacentHTML('beforeend', `<hinzugefuegt-overlay component="hinzugefuegt-overlay"><dialog class="element-overlay-slide-in step0overlay" dl-name="step-0-overlay"></dialog></hinzugefuegt-overlay>`);

function initBrowserBackListener() {
  window.addEventListener('popstate', popstateEventListener);
}

function popstateEventListener() {
  const dialog = document.querySelector(".step0overlay");
  if(dialog.hasAttribute("open")) {
    dialog.close();
    window.removeEventListener('popstate', popstateEventListener);
  }    
}