export function appendHtml(container, html) {
    const range = document.createRange();
    range.setStart(container, 0);
    range.setEnd(container, 0);
    container.appendChild(range.createContextualFragment(html));
}

export function changeHTML(container, html) {
    container.innerHTML = "";
    appendHtml(container, html);
}